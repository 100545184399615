import styled from 'styled-components';

export const MainContentContainer = styled.div
`
max-width: 1200px; 
margin: 20px auto; 
padding: 2rem; 
background-color: #fffff;
// border-style: solid;
// border-width: 1px;
// border-color: black;
`
;