import React from 'react';
import Navigation from '../components/adsteam/navigation/Navigation';



const Ads = () => {


    return ( 
        <div>
            <Navigation />
        </div>
     );
}
 
export default Ads;
