import styled from 'styled-components';

export const DashboardItemContainer = styled.div`
  width: 300px;
  height: 300px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  margin: 10px;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid lightgrey;
  }
`;

export const CategoryContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`

export const DataLabel = styled.h3`
  margin: 0;
  font-size: 1.25rem;
`;

export const DataValue = styled.p`
  margin: 0;
  font-size: 1rem;
`;

export const Subcategories = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const DashSections = styled.div`
flex: 1;
margin: 1rem;
border: solid 1px black;
padding: 10px;
`;

export const DashMain = styled.div`
display: flex;
justify-content: center;
`;
