import axios from 'axios';

const api = axios.create({
  baseURL: 'https://your-api-base-url.com', // Replace with your actual API base URL
});

export const fetchDataFromDatabase = async () => {
  try {
    const response = await api.post('/viewDatabase');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

export const startUpdateCycle = async (row) => {
  try {
    const response = await api.post('/startUpdateCycle', { row });
    return response.data;
  } catch (error) {
    console.error('Error starting update cycle:', error);
    return null;
  }
};

export const stopUpdateCycle = async () => {
  try {
    const response = await api.post('/stopUpdateCycle');
    return response.data;
  } catch (error) {
    console.error('Error stopping update cycle:', error);
    return null;
  }
};

