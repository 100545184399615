import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavbarContainer = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 1rem 2rem;
  
`;

export const NavLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;


export const Logo = styled(NavLink)`
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: black;
`;

export const NavLinks = styled.div`
  a {
    text-decoration: none;
    margin-left: 1rem;
    color: black;
    border: 1px solid lightgrey;
    padding: 10px;

    &.active {
      font-weight: bold;
      color: red;
    }
    &:hover {
      color: red;
      
    }
  }
`;
