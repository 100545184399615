import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 1rem;
  border-top: 1px solid lightgrey;
  font-size: 0.9rem;
`;