import React, { useState } from 'react';
import QuickView from '../components/dashboard/QuickView';
import { CategoryContainer, DashSections, DashMain } from '../styled/DashboardItem.styled';
import Table from '../components/dashboard/Table';
import axios from 'axios';


const HomePage = () => {
  const handleSubcategoryClick = async (subcategory) => {
    const response = await axios.post('https://jsonplaceholder.typicode.com/posts', subcategory);
    console.log('Response data:', response.data); // Add this line
    setTableData([response.data]); // Wrap the response data in an array
  };

  const [tableData, setTableData] = useState([]);


  return (
    <div>
    <CategoryContainer>
      <QuickView title="Revenue" apiEndpoint="/posts" onSubcategoryClick={handleSubcategoryClick} />
      <QuickView title="Expenses" apiEndpoint="https://jsonplaceholder.typicode.com/posts" onSubcategoryClick={handleSubcategoryClick} />
      <QuickView title="New Users" apiEndpoint="https://jsonplaceholder.typicode.com/posts" onSubcategoryClick={handleSubcategoryClick} />
    </CategoryContainer>
    <DashMain>
        {/* <DashSections>
          <PieChartComponent />
        </DashSections> */}
        <DashSections>
          <Table data={tableData}/>
        </DashSections>
    </DashMain>
    </div>

  );
};

export default HomePage;
