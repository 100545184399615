import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  background-color: white;
  color: black;
  border: 1px solid grey;
  margin: 10px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

export default function BackButton({ onClick }) {
  return <Button onClick={onClick}>Back</Button>;
}
