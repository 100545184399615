const navItems = [
    {
      id: 1,
      title: 'Home',
      path: '/home',
    },
    {
      id: 2,
      title: 'Schedule',
      path: '/schedule',
    },
    {
      id: 3,
      title: 'Ads Team',
      path: '/ads',
    },
    {
      id: 4,
      title: 'Content Team',
      path: '/content',
    },
    {
      id: 5,
      title: 'Admin',
      path: '/admin',
    },
  ];
  
  export default navItems;
  