import React, { useState } from 'react';
import styled from 'styled-components';
import Table from '../../dashboard/Table';
import { fetchDataFromDatabase, startUpdateCycle, stopUpdateCycle } from '../../api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  background-color: white;
  color: black;
  border: 1px solid grey;
  margin: 10px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: red;
    color: white;
  }
`;

const ConsoleLog = styled.pre`
  background-color: black;
  color: white;
  padding: 10px;
  width: 100%;
  max-height: 300px;
  overflow: auto;
`;

const ButtonsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2px;
`;


export default function ControlPanel() {
  const [showConsole, setShowConsole] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [consoleLog, setConsoleLog] = useState('');
  const [tableData, setTableData] = useState([
    { ChatID: 1, Group: 'Group 1'},
    { ChatID: 2, Group: 'Group 2'},
    { ChatID: 3, Group: 'Group 3'},
  ]);

  const handleButtonClick = async (action) => {
    switch (action) {
        case 'viewDatabase':
            const data = await fetchDataFromDatabase();
            if (data) {
              setTableData(data);
            }
            break;
      
        case 'startUpdateCycle':
            const row = prompt('Enter the row number to start at:');
            if (row !== null) {
              const response = await startUpdateCycle(parseInt(row, 10));
              if (response) {
                setConsoleLog(`Update Cycle Initialized - Starting Row: ${row}`);
              }
            }
        break;
      
        case 'stopUpdateCycle':
            const stopResponse = await stopUpdateCycle();
            if (stopResponse) {
              setConsoleLog(`Update Cycle Stopped`);
            }
        break;


        case 'updateDatabase':
        break;


      case 'toggleConsole':
        setShowConsole(!showConsole);
        break;

      case 'toggleTable':
        setShowTable(!showTable);
        break; 

      default:
        break;
    }
  };

  return (
    <Container>
        <ButtonsGrid>
      <Button onClick={() => handleButtonClick('startUpdateCycle')}>Start Update Cycle</Button>
      <Button onClick={() => handleButtonClick('updateDatabase')}>Update Database</Button>
      <Button onClick={() => handleButtonClick('toggleConsole')}>Show Console</Button>
      <Button onClick={() => handleButtonClick('stopUpdateCycle')}>Stop Update Cycle</Button>
      <Button onClick={() => handleButtonClick('viewDatabase')}>View Database</Button>
      <Button onClick={() => handleButtonClick('toggleTable')}>Show Table</Button>
      </ButtonsGrid>
      {showConsole && <ConsoleLog>{consoleLog}</ConsoleLog>}
      {showTable && <Table data={tableData}/>}
    </Container>
  );
}
