import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import MainContent from './components/layout/MainContent';
import Footer from './components/layout/Footer';
import HomePage from './pages/HomePage';
import Ads from './pages/Ads';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Logo } from './styled/Navbar.styled';

function App() {
  return (
    <Router>
      <DndProvider backend={HTML5Backend}>
      <Logo to="/">Isakov Industries</Logo>
      <MainContent>
      <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/ads" element={<Ads />} />
          <Route path="/button2" element={<div>Button 2</div>} />
          <Route path="/button3" element={<div>Button 3</div>} />
          <Route path="/button4" element={<div>Button 4</div>} />
          <Route path="/button5" element={<div>Button 5</div>} />
        </Routes>
      </MainContent>
      <Footer />
      </DndProvider>
    </Router>
  );
}

export default App;
