import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavbarContainer, NavLinks, NavLinksWrapper } from '../../styled/Navbar.styled';
import navItems from './navItems';

const Navbar = () => {
  return (
    <NavbarContainer>
      <NavLinksWrapper>
        <NavLinks>
          {navItems.map((navItem) => (
            <NavLink key={navItem.id} to={navItem.path} activeClassName="active">
              {navItem.title}
            </NavLink>
          ))}
        </NavLinks>
      </NavLinksWrapper>
    </NavbarContainer>
  );
};


export default Navbar;
