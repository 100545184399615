import React, { useState, useEffect } from 'react';
import { DashboardItemContainer, DataLabel, DataValue, Subcategories } from '../../styled/DashboardItem.styled';
import axios from 'axios';
import styled from 'styled-components';


const DashboardItemSubcategory = styled.div`
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

const Quickview = ({ title, apiEndpoint, onSubcategoryClick }) => {
  const [value, setValue] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [showSubcategories, setShowSubcategories] = useState(false);

  const handleMainClick = () => {
    setShowSubcategories(!showSubcategories);
  };

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(apiEndpoint);
      // For this example, we'll just use the first post's title as the total value
      setValue(response.data[0].title);
      // We'll use the first five posts as subcategories
      setSubcategories(response.data.slice(0, 5).map(post => ({ name: post.title, value: post.id })));
    }

    fetchData();
  }, [apiEndpoint]);

  return (
    <DashboardItemContainer onClick={handleMainClick}>
      <DataLabel>{title}</DataLabel>
      <DataValue>{value}</DataValue>
      {showSubcategories && (
        <Subcategories>
          {subcategories.map((subcategory, index) => (
            <DashboardItemSubcategory
              key={index}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the parent click event from triggering
                onSubcategoryClick(subcategory);
              }}
            >
              {subcategory.name}: {subcategory.value}
            </DashboardItemSubcategory>
          ))}
        </Subcategories>
      )}
    </DashboardItemContainer>
  );
};

export default Quickview;
