import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #ccc;
    padding: 0.5rem;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  tbody tr:hover {
    background-color: #f5f5f5;
  }
`;

const Button = styled.button`
  background-color: white;
  color: black;
  border: 1px solid grey;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    color: red;
  }
`

const Table = ({ data }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return null;
  }

  const handleDownloadCSV = () => {
    const rows = []; // Initialize the rows array
    const headers = Object.keys(data[0]); // Get the headers from the first item in the data array
    rows.push(headers); // Add the headers as the first row of the CSV file
    data.forEach((item) => {
      const row = headers.map((header) => item[header]); // Create a new row for each item in data
      rows.push(row); // Add the row to the rows array
    });

    const csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n"); // Create a CSV string
    const encodedUri = encodeURI(csvContent); // Encode the CSV string as a data URI
    const link = document.createElement("a"); // Create a new anchor element
    link.setAttribute("href", encodedUri); // Set the anchor element's href attribute to the data URI
    link.setAttribute("download", "data.csv"); // Set the anchor element's download attribute to the desired file name
    document.body.appendChild(link); // Append the anchor element to the DOM
    link.click(); // Trigger a click event on the anchor element to download the CSV file
    document.body.removeChild(link); // Remove the anchor element from the DOM
  };

  const columnNames = Object.keys(data[0]);

  return (
    <>
          {data.length > 0 && (
        <Button onClick={handleDownloadCSV}>Save as CSV</Button>
      )}
      <StyledTable>
        <thead>
          <tr>
            {columnNames.map((columnName, index) => (
              <th key={index}>{columnName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {columnNames.map((columnName, cellIndex) => (
                <td key={cellIndex}>{item[columnName]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};

export default Table;
