import React, { useState } from 'react';
import styled from 'styled-components';
import FtlPuller from '../ftldata/FtlPuller';
import ControlPanel from '../brycebot/ControlPanel';
import BackButton from './BackButton';
import { CategoryContainer } from '../../../styled/DashboardItem.styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: black;
`;

const Button = styled.button`
  width: 125px;
  height: 75px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  margin: 10px;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid red;
  }
`;

export default function Navigation() {
  const [currentComponent, setCurrentComponent] = useState(null);

  const handleButtonClick = (component) => {
    setCurrentComponent(component);
  };

  const handleBack = () => {
    setCurrentComponent(null);
  };

  if (currentComponent) {
    return (
      <>
        <BackButton onClick={handleBack} />
        {currentComponent}
      </>
    );
  }

  return (
    <Container>
      <Title>new and improved</Title>
      <CategoryContainer>
      <Button onClick={() => handleButtonClick(<FtlPuller />)}>FTL Lookup</Button>
      <Button onClick={() => handleButtonClick(<ControlPanel />)}>BryceBot</Button>
      </CategoryContainer>

    </Container>
  );
}
