import React, { useState } from 'react';
import Table from '../../dashboard/Table';
import { CategoryContainer, DashSections } from '../../../styled/DashboardItem.styled';
import axios from 'axios';
import styled from 'styled-components';

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
`;

const Input = styled.input`
  margin: 10px 0;
  padding: 12px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  background-color: white;
  color: black;
  border: 1px solid grey;
  margin: 10px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;


const FtlPuller = () => {

    const [tableData, setTableData] = useState([]);
    const [formData, setFormData] = useState({ freeTrial: '' });

    const handleInputChange = event => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        const response = await axios.post('https://jsonplaceholder.typicode.com/posts', { title: formData.freeTrial }); // Update axios.post() call to include form data
        setTableData([response.data]); // Update tableData state
    };


    return (
        <>
        <CategoryContainer>
        <FormWrapper onSubmit={handleSubmit}>
            <label>FTL Lookup</label>
            <Input
                type="text"
                name="freeTrial"
                value={formData.freeTrial}
                onChange={handleInputChange}
                placeholder="Link Here"
            />
            <Button type="submit">Search</Button>           
        </FormWrapper>
    </CategoryContainer>
    <DashSections>
        <Table data={tableData}/>
    </DashSections>
    </>

      );
}
 
export default FtlPuller;